<template>
  <v-card class="border-box cardBg rounded-20" v-bind="$attrs" flat outlined>
    <v-row class="group-content d-block d-md-flex align-center py-20 px-25" no-gutters>
      <v-col cols="12" md="3" xl="2">
        <TextDescription :description="description"/>
      </v-col>

      <v-col cols="12" md="9" xl="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text">Search</span>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="search"
              class="field-container"
            />
          </v-col>

          <v-col
            cols="12" sm="8" md="9" offset="0" offset-sm="4" offset-md="3"
            class="mt-2 mt-sm-8"
          >
            <v-list class="cardBg py-0" max-width="560">
              <v-list-item
                class="mb-8 pa-8 template-item"
                @click="selectTemplate(defaultTemplate)"
              >
                <v-avatar class="mr-7" size="42" color="white">
                  <v-icon size="18" color="black">$plusIcon</v-icon>
                </v-avatar>

                <v-list-item-content>
                  <v-list-item-title class="font-normal">Create custom Traffic Source</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-for="template in localTemplates"
                :key="template.id"
                class="mb-8 pa-8 template-item"
                @click="selectTemplate(template)"
              >
<!--                <v-avatar class="mr-7" size="42" color="white"></v-avatar>-->

                <v-list-item-content>
                  <v-list-item-title class="font-normal ml-7" v-html="template.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import FormField from '@/components/inputs/FormField'

import { getAllTrafficSourcesTemplates } from '@/api/traffic-source-routes'

export default {
  name: 'CardTemplates',
  inheritAttrs: false,
  components: { TextDescription, ActionOutlinedButton, FormField },
  data: () => ({
    loading: true,
    description: {
      title: 'Add traffic source',
      text: [
        'To create a traffic source,',
        'choose a template or create custom'
      ]
    },
    search: null,
    templates: [],
    defaultTemplate: {
      'postback_url': null,
      'url_params': []
    },
  }),
  computed: {
    localTemplates() {
      return !!this.search ? this.templates.filter(t => t.name.toLowerCase().includes(this.search.toLowerCase())) : this.templates
    }
  },
  mounted() {
    this.loadTemplates()
  },
  methods: {
    async loadTemplates() {
      this.loading = true

      const { success, payload, message } = await getAllTrafficSourcesTemplates()

      if (success) {
        this.templates = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    selectTemplate(template) {
      this.$emit('select', template)
    },
  },
}
</script>