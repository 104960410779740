<template>
  <v-container fluid class="pa-8 pa-sm-15">
  <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
    <div class="d-flex align-center">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15">Traffic source</div>
      </portal>

      <ActionButton @click="closeAction">Manage traffic sources</ActionButton>
    </div>

    <div>
      <CancelButton @click="closeAction">Close</CancelButton>
    </div>
  </div>
    <CardDetailTrafficSource
      v-if="selectedTemplate || trafficSourceSlug"
      :trafficSourceSlug="trafficSourceSlug"
      :selectedTemplate="selectedTemplate"
      @goBack="goBack"
    />

    <CardTemplates
      v-else
      @select="selectTemplate"
    />
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import CardTemplates from '@/components/cards/CardTemplates'
import CardDetailTrafficSource from '@/components/cards/CardDetailTrafficSource'

export default {
  name: 'DetailTrafficSource',
  components: { ActionButton, CancelButton, CardTemplates, CardDetailTrafficSource },
  data: () => ({
    loading: false,
    trafficSourceSlug: null,
    selectedTemplate: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.trafficSourceSlug = this.$route.params.slug
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageTrafficSources' })
    },
    selectTemplate(template) {
      this.selectedTemplate = template
    },
    goBack() {
      this.selectedTemplate = null
    },
  },
}
</script>