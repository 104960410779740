<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-form ref="formUrlParams" v-model="validateForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="trafficSourceNameParam"
            ></portal-target>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="formData.name"
              :rules="nameRules"
              class="field-container"
            >
              <template #message="{ key, message }">
                <portal to="trafficSourceNameParam" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">URL Token</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="trafficSourceParamUrlName"
            ></portal-target>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="formData['url_param']"
              :rules="paramRules"
              class="field-container"
            >
              <template #message="{ key, message }">
                <portal to="trafficSourceParamUrlName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton class="mr-5 mr-sm-10" @click="closePanel">Cancel</CancelButton>

            <ActionButton @click="submitForm">Add</ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import FormField from '@/components/inputs/FormField'

import { randomID, compareDifferenceStrings } from '@/helper/app-helper'

export default {
  name: 'PramsPanel',
  inheritAttrs: false,
  components: { TextDescription, CancelButton, ActionButton, FormField },
  props: {
    addedParams: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    validateForm: true,
    description: {
      title: 'Add URL parameter',
    },
    formData: {
      id: randomID(),
      active: 1,
      name: null,
      'url_param': null,
      created: true,
    },
    nameRules: [],
    paramRules: [],
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    async submitForm() {
      await this.validationForm()

      if (this.$refs.formUrlParams.validate()) {
        this.$emit('addSuccess', this.formData)
      }
    },
    validationForm() {
      this.nameRules = [
        v => !!v || 'This field is required',
        v => this.addedParams.every(p => compareDifferenceStrings(p.name, v)) || 'Name must not be repeated',
      ]
      this.paramRules = [
        v => !!v || 'This field is required',
        v => this.addedParams.every(p => compareDifferenceStrings(p['url_param'], v)) || 'Token must not be repeated',
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
  },
}
</script>