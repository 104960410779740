<template>
  <v-card class="border-box cardBg rounded-20" v-bind="$attrs" flat outlined>
    <v-form ref="formTrafficSource" v-model="validateForm">
      <v-row class="group-content d-block d-md-flex align-center pt-20 px-25" no-gutters>
        <v-col cols="12" md="3" xl="2">
          <TextDescription :description="description.setup"/>
        </v-col>

        <v-col cols="12" md="9" xl="10">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text">Traffic source name</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="trafficSourceName"
              ></portal-target>
            </v-col>

            <v-col cols="12" sm="8" md="9">
              <FormField
                v-model="formData.name"
                class="field-container"
                :rules="rules"
                :loading="loadingDetail"
              >
                <template #message="{ key, message }">
                  <portal to="trafficSourceName" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>

            <v-col
              class="divider-container pt-16 pt-sm-25"
              cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
            >
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row id="traffic-source-params" class="group-content d-block d-md-flex align-center pt-16 px-25 pt-sm-25" no-gutters>
        <v-col cols="12" md="3" xl="2">
          <TextDescription :description="description.params"/>
        </v-col>

        <v-col cols="12" md="9" xl="10">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column flex-md-row justify-space-between table-container pb-10"
              cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
            >
              <div class="d-flex">
                <SearchField
                  v-model="search"
                  class="mr-4 mr-sm-11 mb-4"
                  :height="48"
                />

                <TooltipAction bottom message="Delete selected parameters">
                  <template #activator>
                    <IconButton
                      class="mr-5 mr-md-11"
                      icon="deleteBothIcon"
                      :disabled="!selectedParams.length"
                      @click="openDialogDeleteSelectedParams"
                    />
                  </template>
                </TooltipAction>
              </div>

              <ActionOutlinedButton
                class="ml-0 ml-md-11 mt-6 mt-md-0"
                @click="openAddParamPanel"
                height="46"
                max-width="200"
              >
                Add parameter
              </ActionOutlinedButton>
            </v-col>

            <v-col
              class="table-container pb-16 pb-sm-25"
              cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
            >
              <TrafficSourceParams
                :params.sync="params"
                :loadingDetail="loadingDetail"
                :trafficSourceSlug="trafficSourceSlug"
                :search="search"
                :selectedParams.sync="selectedParams"
              />
            </v-col>

            <v-col
              v-if="!visiblePanel"
              class="divider-container"
              cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
            >
              <v-divider />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <div
          id="traffic-source-params-panel"
          class="group-content expandPanelBg"
          v-if="visiblePanel"
        >
          <PramsPanel
            :addedParams="params"
            @addSuccess="addSuccess"
            @closePanel="closePanel"
          />
        </div>
      </v-slide-y-transition>

      <v-row class="group-content d-block d-md-flex align-center pt-16 px-25 pt-sm-25" no-gutters>
        <v-col cols="12" md="3" xl="2">
          <TextDescription :description="description.url"/>
        </v-col>

        <v-col cols="12" md="9" xl="10">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11"
              :class="{ 'pb-4 pb-sm-10': enabledPostbackURL }"
              cols="12" sm="4" md="3"
            >
              <span class="font-normal nameField--text">Traffic Source postback URL</span>
            </v-col>

            <v-col class="my-auto" :class="{ 'pb-10': enabledPostbackURL }" cols="12" sm="8" md="9">
              <SwitchLg
                v-model="enabledPostbackURL"
                bgColor="switcherViolet"
              />
            </v-col>
          </v-row>

          <v-slide-y-transition>
            <v-row no-gutters v-if="enabledPostbackURL">
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="4" md="3"
              >
                <span class="font-normal nameField--text">Event</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="trafficSourceEvent"
                ></portal-target>
              </v-col>

              <v-col class="pb-10" cols="12" sm="8" md="9">
                <AutocompleteField
                  class="field-container"
                  v-model="formData['event_id']"
                  :items="events"
                  item-text="name"
                  item-value="id"
                  :loading="loadingDetail"
                  :rules="rulesEvents"
                  :disabled="!enabledPostbackURL"
                  checkBg
                >
                  <template #message="{ key, message }">
                    <portal to="trafficSourceEvent" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </AutocompleteField>
              </v-col>

              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-6"
                cols="12" sm="4" md="3"
              >
                <span class="font-normal nameField--text">Postback URL</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="trafficSourcePostbackURL"
                ></portal-target>
              </v-col>

              <v-col class="pb-6" cols="12" sm="8" md="9">
                <FormTextarea
                  ref="postbackUrl"
                  v-model.trim="formData['postback_url']"
                  :rules="urlRules"
                  class="field-container-postback-url mr-sm-11"
                  rows="1"
                  auto-grow
                  :disabled="!enabledPostbackURL"
                >
                  <template #message="{ key, message }">
                    <portal to="trafficSourcePostbackURL" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormTextarea>
              </v-col>

              <v-col class="d-flex flex-wrap table-container" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
                <div
                  class="postback-url-parameter px-5 py-4 mr-5 mb-5 font-normal"
                  :class="{ 'disabled': !enabledPostbackURL }"
                  v-for="(item, index) in allTrees"
                  :key="index"
                  @click="addParamToURL(item)"
                >
                  {{ item }}
                </div>
              </v-col>
            </v-row>
          </v-slide-y-transition>
        </v-col>
      </v-row>

      <v-row class="group-content d-block d-md-flex align-center px-25 pt-20" no-gutters>
        <v-col cols="12" md="9" xl="10" offset-md="3" offset-xl="2">
          <v-row no-gutters>
            <v-col class="pb-20 d-flex flex-wrap" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
              <ActionOutlinedButton class="mr-8" @click="goBack">Back</ActionOutlinedButton>

              <ActionButton :loading="loading" @click="saveTrafficSource">Save</ActionButton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="goToDeleteParams"
    />
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import ActionButton from '@/components/buttons/ActionButton'
import FormField from '@/components/inputs/FormField'
import SearchField from '@/components/inputs/SearchField'
import FormTextarea from '@/components/inputs/FormTextarea'
import TooltipAction from '@/components/tooltip/TooltipAction'
import IconButton from '@/components/buttons/IconButton'
import TrafficSourceParams from '@/components/tables/TrafficSourceParams'
import PramsPanel from '@/components/trafficSource/PramsPanel'
import SwitchLg from '@/components/controls/SwitchLg'
import AutocompleteField from '@/components/select/AutocompleteField'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { getSystemParams, getAllTrafficSourcesEvents, createTrafficSource, updateTrafficSource, getDerailTrafficSource, detachUrlParamsFromTrafficSource } from '@/api/traffic-source-routes'

export default {
  name: 'CardDetailTrafficSource',
  inheritAttrs: false,
  components: { TextDescription, ActionButton, ActionOutlinedButton, FormField, SearchField, FormTextarea, TooltipAction, IconButton, TrafficSourceParams, PramsPanel, SwitchLg, AutocompleteField, DialogConfirm },
  props: {
    trafficSourceSlug: {
      type: String | Number,
      default: null
    },
    selectedTemplate: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loading: false,
    loadingDetail: false,
    visiblePanel: false,
    loadingAction: false,
    validateForm: true,
    enabledPostbackURL: false,
    events: [],
    description: {
      setup: {
        title: 'Setup Traffic Source',
      },
      params: {
        title: 'Parameters',
      },
      url: {
        title: 'Passing conversion into to traffic source',
      },
    },
    formData: {
      name: null,
      'url_params': [],
      'event_id': null,
      'postback_url': null,
    },
    trafficSource: null,
    search: null,
    rules: [],
    rulesEvents: [],
    urlRules: [],
    selectedParams: [],
    systemParams: [],
    params: [],
    trees: {},
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    allTrees() {
      const params = !!this.formData.name ? this.params.map(p => `{${this.formData.name}:${p.name}}`) : []
      return [
        ...params,
        ...this.systemParams.map(p => `{${p['group_param']}:${p.name}}`),
      ]
    }
  },
  mounted() {
    this.loadSystemParams()
    if (this.trafficSourceSlug) {
      this.loadTrafficSourceDetail()
    } else {
      this.parseProps()
    }

    this.loadAllEvents()
  },
  methods: {
    async loadSystemParams() {
      const { success, payload, message } = await getSystemParams()

      if (success) {
        this.systemParams = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    parseProps() {
      this.params = this.selectedTemplate['url_params']
      this.formData['postback_url'] = this.selectedTemplate['postback_url']

      this.enabledPostbackURL = !!this.params.length || !!this.formData['postback_url']
    },
    async loadTrafficSourceDetail() {
      this.loadingDetail = true

      const { success, payload, message } = await getDerailTrafficSource(this.trafficSourceSlug)

      if (success) {
        this.trafficSource = payload

        this.parsePayload()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingDetail = false
    },
    parsePayload() {
      this.formData.name = this.trafficSource.name
      this.params = this.trafficSource['url_params']

      if (this.trafficSource['event_id'] && this.trafficSource['postback_url']) {
        this.enabledPostbackURL = true
        this.formData['event_id'] = this.trafficSource['event_id']
        this.formData['postback_url'] = this.trafficSource['postback_url']
      } else {
        this.enabledPostbackURL = false
        this.formData['event_id'] = null
        this.formData['postback_url'] = null
      }
    },
    async loadAllEvents() {
      const { success, payload, message } = await getAllTrafficSourcesEvents()

      if (success) {
        this.events = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    openDialogDeleteSelectedParams() {
      this.dialogTitle = 'Detach selected Parameters'
      this.dialogSubTitle = ''
      this.dialogMessage = 'Are you sure you want to delete selected Parameters?'
      this.confirmDialog = true
    },
    async goToDeleteParams() {
      if (!!this.trafficSourceSlug && this.selectedParams.some(p => !p.created)) {
        this.loadingAction = true

        const { success, payload, message } = await detachUrlParamsFromTrafficSource({
          trafficSourceId: this.trafficSourceSlug,
          params: { 'url_params': this.selectedParams.filter(p => !p.created).map(p => p.id) }
        })

        if (success) {
          this.systemParams = payload
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      } else {
        this.$notify({ type: 'success-bg', text: 'URL param has been detached' })
      }


      this.params = this.params.filter(p => this.selectedParams.every(sp => sp.id !== p.id))
      this.selectedParams = []
      this.confirmDialog = false
    },
    openAddParamPanel() {
      this.visiblePanel = true
      this.scrollTo()
    },
    addSuccess(newParams) {
      this.params.push(newParams)
      this.closePanel()
    },
    goBack() {
      if (this.trafficSourceSlug) {
        this.goToManagePage()
      } else {
        this.$emit('goBack')
      }
    },
    async saveTrafficSource() {
      await this.validationForm()

      if (this.$refs.formTrafficSource.validate()) {
        this.loading = true

        this.formData['url_params'] = this.params.map(p => ({
          ...p,
          id: !!p.created || !this.trafficSourceSlug ? null : p.id,
          'group_param': this.formData.name,
          created: undefined
        }))

        if (!!this.trafficSourceSlug) {
          this.updateTrafficSource(this.formData)
        } else {
          this.createTrafficSource(this.formData)
        }

      }
    },
    async createTrafficSource(formData) {
      const { success, payload, message } = await createTrafficSource(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.goToManagePage()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async updateTrafficSource(formData) {
      const { success, payload, message } = await updateTrafficSource({
        formData,
        trafficSourceId: this.trafficSourceSlug
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.goToManagePage()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    goToManagePage() {
      this.$router.push({ name: 'ManageTrafficSources' })
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
      if (this.enabledPostbackURL) {
        this.rulesEvents = [
          v => !!v || 'This field is required'
        ]
        this.urlRules = [
          v => !!v || 'This field is required',
          v => this.checkUrl(v) || 'URL must be valid'
        ]
      } else {
        this.rulesEvents = []
        this.urlRules = []
      }
    },
    checkUrl(url) {
      return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
    },
    addParamToURL(code) {
      if (!this.enabledPostbackURL) {
        return
      }

      this.formData['postback_url'] = !!this.formData['postback_url'] ? this.formData['postback_url'] + code : code
      this.$refs['postbackUrl'].$refs['textareaRef'].$refs.input.focus()
    },
    scrollTo(value = true, offset = 0) {
      if (value) {
        setTimeout(() => {
          this.$vuetify.goTo('#traffic-source-params-panel', { offset })
        })
      } else {
        this.$vuetify.goTo('#traffic-source-params')
      }
    },
    closePanel() {
      this.visiblePanel = false
      this.scrollTo(false)
    },
  },
  watch: {
    enabledPostbackURL: {
      handler(value) {
        if (value) {
          if (this.trafficSourceSlug) {
            this.formData['event_id'] = this.trafficSource['event_id']
            this.formData['postback_url'] = this.trafficSource['postback_url']
          } else {
            this.formData['event_id'] = this.selectedTemplate['event_id']
            this.formData['postback_url'] = this.selectedTemplate['postback_url']
          }
        } else {
          this.rulesEvents = []
          this.urlRules = []
          this.formData['event_id'] = null
          this.formData['postback_url'] = null
        }
      }
    }
  }
}
</script>