<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :search="search"
    item-key="id"
    class="elevation-0 borderNone font-small tableBg borderTableXS"
    outlined
    disable-pagination
    hide-default-footer
    show-select
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :loading="loadingDetail"
    :loader-height="2"
    :mobile-breakpoint="0"
  >
    <template #header.data-table-select="{ on, props }">
      <PrimaryCheckbox
        v-bind="props"
        v-on="on"
        hide-details
      />
    </template>

    <template #item.data-table-select="{ select, isSelected }">
      <PrimaryCheckbox
        v-model="isSelected"
        @change="select"
        hide-details
      />
    </template>

    <template #no-data>
      <div class="d-flex">
        <span class="font-normal text-none text-left">No Parameters linked to this Traffic Source</span>
      </div>
    </template>

    <template #no-results>
      <div class="d-flex">
        <span class="font-normal text-none text-left">No results found</span>
      </div>
    </template>

    <template #item.active="{ item }">
      <SwitchSm
        :value="getStatus(item)"
        @input="changeStatus(item)"
        bgColor="switcherYellow"
      />
    </template>

    <template #item.actions="{ item }">
      <TooltipAction bottom message="Delete">
        <template #activator>
          <v-icon @click="openDetachUrlParamsDialog(item)">$deleteIcon</v-icon>
        </template>
      </TooltipAction>
    </template>

    <template #top>
      <DialogConfirm
        v-model="confirmDialog"
        :loading="loadingAction"
        :title="dialogTitle"
        :subTitle="dialogSubTitle"
        :message="dialogMessage"
        @actionSuccess="goToDeleteUrlParams"
      />
    </template>
  </v-data-table>
</template>

<script>
import TooltipAction from '@/components/tooltip/TooltipAction'
import SwitchSm from '@/components/controls/SwitchSm'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import { detachUrlParamsFromTrafficSource } from '@/api/traffic-source-routes'

import { changeItemsAfterUpdate } from '@/helper/app-helper'

export default {
  name: 'TrafficSourceParams',
  inheritAttrs: false,
  components: { TooltipAction, SwitchSm, PrimaryCheckbox, DialogConfirm },
  props: {
    params: {
      type: Array,
      required: true
    },
    loadingDetail: {
      type: Boolean,
      required: true
    },
    trafficSourceSlug: {
      type: String | Number,
      required: true
    },
    search: {
      type: String | Number,
      default: ''
    },
    selectedParams: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    headers: [
      { text: 'NAME', value: 'name' },
      { text: 'URL TOKEN', value: 'url_param' },
      { text: 'STATUS', value: 'active', align: 'center' },
      { text: 'ACTIONS', value: 'actions', align: 'right', sortable: false },
    ],
    confirmDialog: false,
    loadingAction: false,
    changedItem: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    trafficSours: null,
  }),
  computed: {
    selected: {
      get() {
        return this.selectedParams
      },
      set(selected) {
        this.$emit('update:selectedParams', selected)
      }
    },
    items: {
      get() {
        return this.params
      },
      set(items) {
        this.$emit('update:params', items)
      },
    },
  },
  methods: {
    openDetachUrlParamsDialog(urlParams) {
      this.changedItem = urlParams
      this.dialogTitle = 'Detach Parameter'
      this.dialogSubTitle = `${urlParams.name}`
      this.dialogMessage = 'Are you sure you want to delete this Parameter?'
      this.confirmDialog = true
    },
    async goToDeleteUrlParams() {
      if (!!this.trafficSourceSlug && !this.changedItem.created) {
        this.loadingAction = true

        const { success, payload, message } = await detachUrlParamsFromTrafficSource({
          trafficSourceId: this.trafficSourceSlug,
          params: { 'url_params': [this.changedItem.id] }
        })

        if (success) {
          this.systemParams = payload
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      } else {
        this.$notify({ type: 'success-bg', text: 'URL param has been detached' })
      }

      this.items = this.items.filter(p => p.id !== this.changedItem.id)

      this.confirmDialog = false
    },
    afterDelete(deletedId) {
      this.items = this.items.filter(item => item.id !== deletedId)
      this.$emit('reloadCampaignManage')
    },
    getStatus(item) {
      return item.active === 1
    },
    changeStatus(item) {
      this.items = changeItemsAfterUpdate(this.items, {
        ...item,
        active: !!item.active ? 0 : 1
      }, 'id')
    },
  },
  watch: {
    search: {
      handler() {
        this.selected = []
      }
    }
  }
}
</script>